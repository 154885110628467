import App from 'next/app';
import { ReCaptchaProvider } from "next-recaptcha-v3";
import GoogleAnalytics from "@bradgarropy/next-google-analytics";
import { fetchGetCompanyGeneralData, fetchGetCompanySEO, fetchInsertPageView } from "../../services/api";
import { FacebookPixel } from "../components/default/facebook-pixel";

import '../styles/app.css';
import '../styles/style.css';
import '../styles/custom.css';
import '../components/layout/header/style.css';
import '../components/layout/mobile-menu/style.css';
import '../components/layout/footer/style.css';
import '../components/home/hero/style.css';
import '../components/home/find-car/style.css';
import '../components/home/hot-offers/style.css';
import '../components/home/services/style.css';
import '../components/home/testimonial/style.css';
import '../components/stock/style.css';
import '../components/contact/contact-form/style.css';
import '../components/about-us/style.css';
import '../components/car-details/style.css';
import '../components/financing/style.css';

import 'swiper/css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function MyApp({ Component, pageProps, seo, companyGeneralData }) {
  return <ReCaptchaProvider reCaptchaKey={JSON.parse(companyGeneralData).company.captchaPublicKey}>
    {
      seo && seo.googleAnalyticsId
        ? <GoogleAnalytics measurementId={seo.googleAnalyticsId} />
        : null
    }
    {
      seo && seo.facebookPixelId
        ? <FacebookPixel facebookPixelId={seo.facebookPixelId} />
        : null
    }
    <Component {...pageProps} companyGeneralData={companyGeneralData} />
  </ReCaptchaProvider>
}

MyApp.getInitialProps = async (context) => {
  const pageProps = await App.getInitialProps(context);
  const seo = await fetchGetCompanySEO();
  const companyGeneralData = await fetchGetCompanyGeneralData();
  fetchInsertPageView({ page: context.router.asPath }).catch(err => { console.log(err.toString()) });

  return { ...pageProps, seo, companyGeneralData };
};

export default MyApp
